// #006541
// #ffee00
// #ff84ff
import styles from "./horizontal.module.css";
import React from "react";

export default function Horizontal(props) {
  return (
    <svg
      viewBox="0 0 1600 1600"
      width="1600"
      height="1600"
      style={{
        width: "100%",
        height: "100%",
        transform: "translate3d(0px, 0px, 0px)",
        contentVisibility: "visible",
      }}
      className={props?.className}
    >
      <defs>
        <clipPath>
          <rect width="1600" height="1600" x="0" y="0"></rect>
        </clipPath>
      </defs>
      <g>
        <g id={styles.buddle} opacity="1" fill="#006541">
          <g opacity="1" transform="matrix(2,0,0,2,0,0)">
            <path
              fillOpacity="1"
              d=" M1.0089999437332153,-114 C-63.055999755859375,-114 -116,-62.064998626708984 -116,2 C-116,2 -116,114 -116,114 C-116,114 2,114 2,114 C64.95999908447266,114 116,62.959999084472656 116,0 C116,-62.959999084472656 63.96900177001953,-114 1.0089999437332153,-114z"
            ></path>
          </g>
        </g>
        <g id={styles.circle} opacity="1" fill="#006541">
          <g
            opacity="1"
            transform="matrix(1,0,0,1,-10.437999725341797,-454.43798828125)"
          >
            <path
              fillOpacity="1"
              d=" M0,-225.5625 C124.48794555664062,-225.5625 225.5625,-124.48794555664062 225.5625,0 C225.5625,124.48794555664062 124.48794555664062,225.5625 0,225.5625 C-124.48794555664062,225.5625 -225.5625,124.48794555664062 -225.5625,0 C-225.5625,-124.48794555664062 -124.48794555664062,-225.5625 0,-225.5625z"
            ></path>
          </g>
        </g>
        <g id={styles.half} opacity="1" fill="#006541">
          <g opacity="1" transform="matrix(2,0,0,2,0,0)">
            <path
              fillOpacity="1"
              d=" M0,103 C132.54800415039062,103 240,10.770999908447266 240,-103 C240,-103 -240,-103 -240,-103 C-240,10.770999908447266 -132.54800415039062,103 0,103z"
            ></path>
          </g>
        </g>
        <g id={styles.purple} opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,512.125,-71.875)">
            <path
              className="ball"
              fillOpacity="1"
              d=" M0,-40.125 C22.15999984741211,-40.125 40.125,-22.15999984741211 40.125,0 C40.125,22.15999984741211 22.15999984741211,40.125 0,40.125 C-22.15999984741211,40.125 -40.125,22.15999984741211 -40.125,0 C-40.125,-22.15999984741211 -22.15999984741211,-40.125 0,-40.125z"
            ></path>
          </g>
        </g>
        <g id={styles.green} opacity="1" fill="#006541">
          <g opacity="1" transform="matrix(1,0,0,1,512.125,-71.875)">
            <path
              className="ball"
              fillOpacity="1"
              d=" M0,-40.125 C22.15999984741211,-40.125 40.125,-22.15999984741211 40.125,0 C40.125,22.15999984741211 22.15999984741211,40.125 0,40.125 C-22.15999984741211,40.125 -40.125,22.15999984741211 -40.125,0 C-40.125,-22.15999984741211 -22.15999984741211,-40.125 0,-40.125z"
            ></path>
          </g>
        </g>
        <g id={styles.yellow} opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,512.125,-71.875)">
            <path
              className="ball"
              fillOpacity="1"
              d=" M0,-40.125 C22.15999984741211,-40.125 40.125,-22.15999984741211 40.125,0 C40.125,22.15999984741211 22.15999984741211,40.125 0,40.125 C-22.15999984741211,40.125 -40.125,22.15999984741211 -40.125,0 C-40.125,-22.15999984741211 -22.15999984741211,-40.125 0,-40.125z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
