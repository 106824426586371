import React from "react";
import Horizontal from "./horizontal.tsx";

const Spin = (props) => {
  const {spinning} = props;
  return (
    <>
      {spinning ? (
        <div className="fixed left-0 top-0 z-top-1 flex size-full items-center justify-center bg-overlay">
          <div style= {{width: "56px", height: "56px", zIndex: "99999"}}>
            {" "}
            <Horizontal />
          </div>
        </div>
      ) : null}
    </>
  );
};

export {Spin};
